export enum ChatModel {
    // 'GPT-4' = 'gpt-4',
    'GPT-4o' = 'gpt-4o',
    'Claude 3 Opus' = 'claude-3-opus-20240229',
    'Gemini 1.5 Pro' = 'gemini-1.5-pro-latest',
    OVH_CODELLAMA_13B = 'CodeLlama-13b-Instruct-hf',
    OVH_LLAMA_2_13B = 'Llama-2-13b-chat-hf',
    'Llama 3.1 70B' = 'Meta-Llama-3_1-70B-Instruct',
    OVH_LLAMA_3_70B = 'Meta-Llama-3-70B-Instruct',
    OVH_LLAMA_3_8B = 'Meta-Llama-3-8B-Instruct',
    OVH_MATHSTRAL_7B = 'mathstral-7B-v0.1',
    'Mistral 7B' = 'Mistral-7B-Instruct-v0.2',
    'Mixtral 22B' = 'Mixtral-8x22B-Instruct-v0.1',
    OVH_MIXTRAL_8X7B = 'Mixtral-8x7B-Instruct-v0.1',
    'Mistral Nemo' = 'Mistral-Nemo-Instruct-2407',
}
