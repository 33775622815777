import { ChatModel } from '../app/chat/chat-model.enum';

export const environment = {
    apiUrl: '/api',
    defaultApi: 'https://app.owlatwork.com/api',

    stripePublishKey:
        'pk_live_51HFxUsLejMtS2pDBJE44rSOaEuCB1qmZgbHIWotxalV3AZVzWLxUQOLaZV9yL5zJQBfAhzaqTok39YA4dLpzsDOR006AXJcrVc',
    stripeDefaultPrice: 'price_1OHnxLLejMtS2pDB2R4DKKLX',

    availableModels: [
        // {
        //     model: ChatModel['GPT-4'],
        //     label: 'GPT-4',
        //     manufacturer: 'Open AI',
        //     description: 'A state-of-the-art model for advanced natural language understanding and generation.',
        // },
        {
            model: ChatModel['GPT-4o'],
            label: 'GPT-4o',
            manufacturer: 'Open AI',
            description: 'An optimized version of GPT-4 focused on efficiency for real-time applications.',
        },
        {
            model: ChatModel['Claude 3 Opus'],
            label: 'Claude 3 Opus',
            manufacturer: 'Anthrophic',
            description:
                'Specializes in providing safe, ethical dialogue with reliable and human-aligned interactions across various contexts.',
        },
        {
            model: ChatModel['Gemini 1.5 Pro'],
            label: 'Gemini 1.5 Pro',
            manufacturer: 'Google',
            description: 'Combines machine learning with multimodal capabilities for versatile problem-solving.',
        },
        {
            model: ChatModel['Llama 3.1 70B'],
            label: 'LLaMA 3.1',
            manufacturer: 'Meta',
            description:
                'LLaMA 3.1 is a sophisticated language model created by Meta for understanding and generating natural language.',
        },
        {
            model: ChatModel['Mistral 7B'],
            label: 'Mistral 7B',
            manufacturer: 'Mistral',
            description:
                'Mistral 7B is a powerful open-weight language model with 7 billion parameters, designed to efficiently process and generate human-like text.',
        },
        /*
        {
            model: ChatModel['Mistral Nemo'],
            label: 'Mistral Nemo',
            manufacturer: 'Mistral',
            description:
                'Mistral NeMo offers a large context window of up to 128k tokens. Its reasoning, world knowledge, and coding accuracy are state-of-the-art in its size category.',
        },
        */
        {
            model: ChatModel['Mixtral 22B'],
            label: 'Mixtral 22B',
            manufacturer: 'Mistral',
            description:
                'Mixtral 22B is a high-performance language model for general-purpose tasks with 22 billion parameters.',
        },
        /*
      {
          model: ChatModel.OVH_MATHSTRAL_7B,
          label: 'OVH MATHSTRAL 7B',
          manufacturer: 'Meta',
          description: 'Mathe-Mann',
      },
      {
          model: ChatModel.OVH_CODELLAMA_13B,
          label: 'OVH CodeLlama 13B',
          manufacturer: 'Meta',
          description: 'Analyze and write code.',
      },
      */
    ],
};
